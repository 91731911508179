import React from "react";
import YoutubeCard from "./YoutubeCard.jsx";

const Youtube = ({ collection: data }) => {
  console.log("🚀 ~ Youtube ~ data:", data)

  return (
    <>
      <div className="col-lg-4 col-md-4 col-sm-6 mb-4 gap-3">
        <YoutubeCard data={data} />
      </div>
    </>
  );
};

export default Youtube;
