import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";

import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import FavoriteOutlinedIcon from "@mui/icons-material/FavoriteOutlined";
import SignLanguageOutlinedIcon from "@mui/icons-material/SignLanguageOutlined";
import SignLanguageIcon from "@mui/icons-material/SignLanguage";
import { FaEye } from "react-icons/fa";

import {
  getMyLinksAsyncThunk,
  deleteLinkAsyncThunk,
  updateLinkReactAsyncThunk,
} from "../../redux/pagesSlices/linkSlice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
// import Btnfav from "../Btnfav";

const YoutubeCard = ({ data }) => {
  console.log("🚀 ~ YoutubeCard ~ data:", data);
  const d = useDispatch();
  const userId = JSON.parse(localStorage.getItem("user"))?.id;
  let BasePath = process.env.REACT_APP_contentBasePath;

  // Calculate total counts
  const totalCounts = data.reactions.reduce((counts, reaction) => {
    counts[reaction.type] = (counts[reaction.type] || 0) + 1;
    return counts;
  }, {});

  const handleReact = async (id, reactionType) => {
    try {
      if (!userId) {
        return;
      }

      const alreadyReacted = data?.reactions.some(
        (item) => item.type == reactionType && item.user == userId
      );
      console.log("🚀 ~ handleReact ~ alreadyReacted:", alreadyReacted);

      if (alreadyReacted) {
        await deleteContentReact(id, reactionType);
      } else {
        await d(updateLinkReactAsyncThunk({ id, data: { reactionType } }));
      }
    } catch (error) {
      console.error("Error updating reaction:", error);
    }
  };

  async function deleteContentReact(id, data) {
    console.log("🚀 ~ deleteContentReact ~ id, data:", id, data);
    try {
      const response = await fetch(`${BasePath}/link/react/${id}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("app-access-token")
          )}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ reactionType: data }),
      });

      if (!response.ok) {
        throw new Error("Error deleting content reaction");
      }

      if (response.status == 200) {
        d(
          getMyLinksAsyncThunk({
            // sortBy: "updatedAt:desc",
            // mimeType: "video",
          })
        );
        // toast.success("Reaction Deleted Successfully!");
      }
      return await response.json();
    } catch (error) {
      console.error("Error deleting content reaction:", error);
      throw error;
    }
  }

  return (
    <div className="videos_card">
      {/* <Card> */}
      <Link to={`/youtube/${data?.id}`}>
        <div
          className="videos_card_flex"
          style={{
            position: "relative",
            width: "100%",
            height: "100%",
          }}
        >
          <div className="card_img" style={{ position: "relative" }}>
            <img
              src={
                data && data.name === "youtube" && data.thumbnailPath
                  ? `${data.thumbnailPath}`
                  : data.name === "google drive"
                  ? "images/Google_Drive_logo.png"
                  : "images/Card-thumbnail.png"
              }
              alt=""
            />
          </div>
          {/* <Card.Title>
            {data?.title ?? data?.name ?? data?.originalName}
            </Card.Title> */}
        </div>
      </Link>
      <div className="heading_box">
        <div className="card_content">
          <h2> {data?.title ?? data?.name ?? data?.originalName}</h2>
        </div>
      </div>
      <div className="reaction_cards">
        <div
          className="social-icons"
          onClick={() => handleReact(data.id, "heart")}
        >
          <p>({totalCounts.heart || 0})</p>
          <span>
            {data.reactions &&
            data.reactions.some(
              (reaction) => reaction.type === "heart" && reaction.user == userId
            ) ? (
              <FavoriteOutlinedIcon />
            ) : (
              <FavoriteBorderOutlinedIcon />
            )}
          </span>
          likes
        </div>

        <div
          className="social-icons"
          onClick={() => handleReact(data.id, "clap")}
        >
          <p>({totalCounts.clap || 0})</p>
          <span>
            {data.reactions &&
            data.reactions.some(
              (reaction) => reaction.type === "clap" && reaction.user === userId
            ) ? (
              <SignLanguageIcon />
            ) : (
              <SignLanguageOutlinedIcon />
            )}
          </span>
          claps
        </div>
        <div
          className="social-icons"
          onClick={() => handleReact(data.id, "clap")}
        >
          <p>({data?.viewsCount || 0})</p>
          <span>
            <FaEye />
          </span>
          views
        </div>
      </div>
      {/* <Card.Body> */}
      {/* Display reaction buttons */}
      {/* <div className="reaction-btns"> */}
      {/* <button
              onClick={() => handleReact(data.id, "heart")}
              className="Likes-btn"
            >
              {data.reactions &&
              data.reactions.some(
                (reaction) =>
                  reaction.type === "heart" && reaction.user === userId
              ) ? (
                <FavoriteOutlinedIcon />
              ) : (
                <FavoriteBorderOutlinedIcon />
              )}
            </button> */}
      {/* <div>({totalCounts.heart || 0})</div> */}
      {/* <button
        onClick={() => handleReact(data.id, "clap")}
        className="Likes-btn clap-btn"
      >
        {data.reactions &&
        data.reactions.some(
          (reaction) => reaction.type === "clap" && reaction.user === userId
        ) ? (
          <SignLanguageIcon />
        ) : (
          <SignLanguageOutlinedIcon />
        )}
      </button> */}
      {/* <div>({totalCounts.clap || 0})</div> */}

      {/* <button className="Likes-btn eye-icon ms-1">
            <FaEye />
          </button>
          <div>({data?.viewsCount || 0})</div>
        </div> */}
      {/* </Card.Body> */}
      {/* </Card> */}
    </div>
  );
};

export default YoutubeCard;
